import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import BookingSession from "../components/BookingSession";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import ToggleButton from "../components/ToggleButton";
import { toast } from "react-toastify";
import axios from "axios";

const ProviderDetail = () => {
  const { fetchSingleProvider } = useFetchContext();
  const { toggleProviderRestriction } = usePostContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [providerDetails, setproviderDetails] = useState(null);
  console.log("Provider Details >", providerDetails);
  const [refundLoading, setRefundLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceToRefund, setPriceToRefund] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [pendingLoading, setPendingLoading] = useState({
    state: false,
    message: "",
  });
  const [isOn, setIsOn] = useState(false);
  const navigate = useNavigate();
  const providerID = providerDetails?.provider?._id;
  const handleOpen = (values) => {
    setPriceToRefund(values.priceToRefund);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePendingAccept = async () => {
    setPendingLoading({ state: true, message: "Accepting" });
    let dataToSend = {
      bookingId: id,
    };
    await acceptPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    setRefreshState((prev) => !prev);
  };
  const handlePendingReject = async () => {
    setPendingLoading({ state: true, message: "Rejecting" });
    let dataToSend = {
      bookingId: id,
    };
    await rejectPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    navigate("/providers");
  };

  useEffect(() => {
    (async () => {
      let response = await fetchSingleProvider(id);
      if (response?.success) {
        setproviderDetails(response?.data);
        setIsOn(response?.data?.isRestricted || false);
      }
    })();
  }, [refreshState]);

  const refundAmount = async () => {
    handleClose();
    setRefundLoading(true);
    let data = {
      paymentIntentId: providerDetails.booking.paymentResult.id,
      priceToRefund,
      bookingId: providerDetails.booking._id,
    };
    await ReturnAppointmentPayment(data);
    setRefundLoading(false);
    navigate("/providers");
  };

  //FORM SCHEMA FOR FORMIK
  const RefundSchema = Yup.object({
    priceToRefund: Yup.number().required("Refund Amount is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      priceToRefund: "",
    },
    validationSchema: RefundSchema,
    onSubmit: (values) => {
      handleOpen(values);
    },
  });

  const handleToggle = async () => {
    const newState = !isOn; // Determine new state
    setPendingLoading(true);

    try {
      await toggleProviderRestriction(newState, providerID);
      setIsOn(newState); // Update the toggle state after API success
    } catch (error) {
      console.error("Error toggling provider status:", error);
    } finally {
      setPendingLoading(false);
    }
  };

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {providerDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl font-medium title-font tracking-wide">
              Provider Details
            </h2>
          </div>

          {/* Provider Overview */}
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex flex-col text-xs md:text-sm">
                <div className="flex justify-between items-center pb-4 border-b border-gray-300">
                  <div className="flex gap-4">
                    <p className="text-lg font-semibold">
                      <span>({providerDetails?.totalReviews || 0})</span> User
                      Reviews
                    </p>
                    <div className="w-[1px] bg-gray-300 h-5" />
                    <p className="text-lg font-semibold">
                      Rating:{" "}
                      {providerDetails?.averageRating?.toFixed(1) || "0.0"}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="text-lg font-semibold">Restrict:</span>
                    <ToggleButton isOn={isOn} onToggle={handleToggle} />
                  </div>
                </div>

                {/* Provider Information */}
                <div className="grid grid-cols-2 gap-4 text-base mt-4">
                  <div>Name</div>
                  <div>
                    {providerDetails?.provider?.user?.firstName || "--"}{" "}
                    {providerDetails?.provider?.user?.lastName || "--"}
                  </div>

                  <div>Email</div>
                  <div>{providerDetails?.provider?.user?.email || "--"}</div>

                  <div>Phone Number</div>
                  <div>
                    {providerDetails?.provider?.user?.phoneNumber || "--"}
                  </div>

                  <div>Joining Date</div>
                  <div>
                    {moment(providerDetails?.provider?.createdAt)
                      .utc()
                      .format("YYYY-MM-DD") || "--"}
                  </div>

                  <div>Status</div>
                  <div>
                    {(providerDetails?.provider?.status || "--").toUpperCase()}
                  </div>

                  <div>Identity Verified</div>
                  <div>
                    {providerDetails?.provider?.isIdentityVerified
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Service Details */}
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-2xl font-medium title-font tracking-wide">
              Service Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="grid grid-cols-2 gap-4 text-base">
                {providerDetails?.services?.map((service) => (
                  <React.Fragment key={service._id}>
                    <div>Service Name</div>
                    <div>{service?.serviceName || "--"}</div>

                    <div>Service Image</div>
                    <div>
                      {service?.image?.url ? (
                        <img
                          src={service.image.url}
                          alt={service.serviceName}
                          className="w-16 h-16 object-cover rounded"
                        />
                      ) : (
                        "--"
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          {/* Verification Details */}
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-2xl font-medium title-font tracking-wide">
              Verification Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="grid grid-cols-2 gap-4 text-base">
                <div>Verification Method</div>
                <div>
                  {providerDetails?.provider?.identityVerification?.method ||
                    "--"}
                </div>

                <div>Verification Document</div>
                <div>
                  {providerDetails?.provider?.identityVerification
                    ?.verificationDocs?.[0]?.url ? (
                    <a
                      href={
                        providerDetails.provider.identityVerification
                          .verificationDocs[0].url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      View Document
                    </a>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProviderDetail;
