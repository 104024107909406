import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthContextProvider } from "./contexts/AuthContext";
import { FetchContextProvider } from "./contexts/FetchContext";
import { PostContextProvider } from "./contexts/PostContext";
import { AdminContextProvider } from "./contexts/AdminContext";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const contextClass = {
  success: "bg-app-blue",
  error: "bg-app-blue",
  info: "bg-app-blue",
  warning: "bg-app-blue",
  default: "bg-app-blue",
};
ReactDOM.render(
  <>
    <ContextProvider>
      <AuthContextProvider>
        <AdminContextProvider>
          <FetchContextProvider>
            <PostContextProvider>
              <App />
            </PostContextProvider>
          </FetchContextProvider>
        </AdminContextProvider>
      </AuthContextProvider>
    </ContextProvider>
    <ToastContainer
      toastClassName={(context) =>
        contextClass[context?.type || "default"] +
        " relative flex p-3 my-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      position="bottom-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Flip}
    />
  </>,
  document.getElementById("root")
);
