import React, { useMemo } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Delete, AccountCircle } from "@mui/icons-material";
import { Header } from "../components";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
const Customers = () => {
  const { users } = useFetchContext();
  const { currentMode } = useStateContext();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      accessorKey: "firstName",
      header: "First Name",
      size: 80,
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
      size: 80,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
  ]);

  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <Header title="Customers" />
      {users === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={users}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Profile">
                  <IconButton
                    onClick={() => {
                      navigate(`/customer/${row.original._id}`);
                    }}
                  >
                    <AccountCircle className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
          {/* {!customers ? <Loading /> : <MaterialReactTable table={table} />} */}
        </div>
      )}
    </div>
  );
};

export default Customers;
