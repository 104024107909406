import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";
import { baseUrl } from "../data/constant";

const INITIAL_STATES = {
  usersLength: null,
  bookingsLength: null,
  servicesLength: null,
  categoriesLength: null,
  providers: null,
  categories: null,
  categoryDetails: null,
  packages: null,
  classes: null,
  totalEarnings: null,
  weeklyEarnings: null,
  recentTransactions: null,
  weeklyStats: null,
  disabledDays: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
  services: null,
};

export const FetchContext = createContext(INITIAL_STATES);

export const FetchContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token } = useAuthContext();

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/category`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, categories: data.data }));
      }
    } catch (error) {
      console.error("Error fetching categories data:", error);
    }
  };

  const fetchSingleCategory = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/category?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          categoryDetails: data.data,
          isLoading: false,
        }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchAllBookings = async (pageIndex = 0, pageSize = 10) => {
    try {
      const page = pageIndex + 1;
      const limit = pageSize;
      const { data } = await axios.get(`${baseUrl}/admin/bookings`, {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        return { data: data.data, totalCount: data.totalCount };
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
      return { data: [], totalCount: 0 };
    }
  };

  const fetchSingleBooking = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/admin/bookings/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchSingleProvider = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(
        `${baseUrl}/provider/public-profile/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchAllProviders = async (pageIndex = 0, pageSize = 10) => {
    console.log(pageIndex, pageSize);
    try {
      const page = pageIndex + 1;
      const limit = pageSize;
      const { data } = await axios.get(`${baseUrl}/admin/get-all-providers`, {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.success) {
        return { data: data.data, totalCount: data.totalCount };
      }
    } catch (error) {
      console.error("Error fetching provider data:", error);
      return { data: [], totalCount: 0 };
    }
  };

  const fetchAllServices = async (pageIndex = 0, pageSize = 10) => {
    console.log(pageIndex, pageSize);
    try {
      const page = pageIndex + 1;
      const limit = pageSize;
      const { data } = await axios.get(`${baseUrl}/admin/get-all-services`, {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.success) {
        return { data: data.data, totalCount: data.totalCount };
      }
    } catch (error) {
      console.error("Error fetching provider data:", error);
      return { data: [], totalCount: 0 };
    }
  };

  const fetchAllUsers = async (pageIndex = 0, pageSize = 10) => {
    console.log(pageIndex, pageSize);
    try {
      const page = pageIndex + 1;
      const limit = pageSize;
      const { data } = await axios.get(`${baseUrl}/admin/get-all-users`, {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.success) {
        return { data: data.data, totalCount: data.totalCount };
      }
    } catch (error) {
      console.error("Error fetching provider data:", error);
      return { data: [], totalCount: 0 };
    }
  };

  const fetchKyc = async (pageIndex = 0, pageSize = 10) => {
    try {
      const page = pageIndex + 1;
      const limit = pageSize;
      const { data } = await axios.get(`${baseUrl}/admin/unverified`, {
        params: { page, limit },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        return { data: data.data, totalCount: data.totalCount };
      }
    } catch (error) {
      console.error("Error fetching unverified providers:", error);
      return { data: [], totalCount: 0 };
    }
  };

  const fetchSingleKyc = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/admin/unverified/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchSingleUserByID = async (id) => {
    try {
      // Set loading state
      setDataState((prevData) => ({ ...prevData, isLoading: true }));

      const response = await axios.get(
        `${baseUrl}/admin/get-user-details/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Ensure response is valid
      if (response?.data?.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return response.data; // Return the actual data
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      setDataState((prevData) => ({
        ...prevData,
        isError: true,
        isLoading: false,
      }));

      console.error("Error fetching user details:", error);
      return null; // Return null on error
    }
  };

  const fetchSingleService = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/service/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchSingleUser = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/user/single/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchWeeklyEarnings = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/weekly-earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          weeklyEarnings: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchTotalEarnings = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/total-earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          totalEarnings: data.totalRevenue,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchRecentTransactions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/recent-transaction`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          recentTransactions: data.bookings,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchWeeklyStats = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/weekly-package`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          weeklyStats: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };

  const getCmsSection = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/admin/get-section-preferences`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };

  const fetchAllBookingsLength = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/admin/get-all-bookings-length`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        setDataState((prev) => ({ ...prev, bookingsLength: data.data }));
      }
    } catch (error) {
      console.error("Error fetchingbooking counts:", error);
    }
  };

  const fetchAllUsersLength = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/admin/get-all-users-length`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        setDataState((prev) => ({ ...prev, usersLength: data.data }));
      }
    } catch (error) {
      console.error("Error fetchingbooking counts:", error);
    }
  };

  const fetchAllServicesLength = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/admin/get-all-services-length`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        setDataState((prev) => ({ ...prev, servicesLength: data.data }));
      }
    } catch (error) {
      console.error("Error fetchingbooking counts:", error);
    }
  };

  const fetchAllCategoriesLength = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/admin/get-all-categories-length`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        setDataState((prev) => ({ ...prev, categoriesLength: data.data }));
      }
    } catch (error) {
      console.error("Error fetchingbooking counts:", error);
    }
  };

  useEffect(() => {
    user && fetchCategories();
    user && fetchAllUsers();
    user && fetchTotalEarnings();
    user && fetchWeeklyEarnings();
    user && fetchRecentTransactions();
    user && fetchWeeklyStats();
    user && getCmsSection();
    user && fetchAllBookingsLength();
    user && fetchAllUsersLength();
    user && fetchAllServicesLength();
    user && fetchAllCategoriesLength();
  }, [user]);

  return (
    <FetchContext.Provider
      value={{
        ...dataState,
        fetchCategories,
        fetchAllUsers,
        fetchSingleUser,
        fetchSingleBooking,
        fetchSingleCategory,
        fetchSingleKyc,
        fetchSingleProvider,
        fetchAllProviders,
        fetchKyc,
        fetchAllBookings,
        getCmsSection,
        fetchAllServices,
        fetchSingleService,
        fetchSingleUser,
        fetchSingleUserByID,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

export const useFetchContext = () => useContext(FetchContext);
