import React, { useEffect, useState } from "react";
import { useFetchContext } from "../contexts/FetchContext";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext"; // Assuming you have the method to update a category

const CategoryDetails = () => {
  const { fetchSingleCategory, updateCategory } = useFetchContext(); // You should have updateCategory method in your context
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadCategory = async () => {
      const response = await fetchSingleCategory(id);
      if (response?.success) {
        setCategory(response?.data[0]); // assuming `data` is an array and we need the first item
      }
    };
    loadCategory();
  }, [id]);

  const handleSubmit = async (values) => {
    const updatedCategoryData = {
      name: values.name,
      picture: values.picture, // If category image is being updated as well
      questions: values.questions.map((question) => ({
        questionText: question.question,
        fields: question.fields.map((field) => ({
          label: field.label,
          fieldType: field.fieldType,
          options: field.options,
          isRequired: field.isRequired,
          image: field.image,
          placeholder: field.placeholder,
        })),
      })),
    };

    // Call the method to update the category, you need to implement `updateCategory` in context
    const response = await updateCategory(id, updatedCategoryData);
    if (response?.success) {
      navigate("/categories"); // Navigate to the categories list after successful update
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 characters")
      .required("Category Name is Required"),
    picture: Yup.string()
      .url("Invalid URL")
      .required("Category Image is Required"),
    questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required("Question text is required"),
        fields: Yup.array().of(
          Yup.object({
            label: Yup.string().required("Field label is required"),
          })
        ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: category?.name || "",
      picture: category?.picture?.url || "",
      questions: category?.questions || [],
    },
    enableReinitialize: true, // Ensures the form is reinitialized when category state is updated
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleQuestionInputChange = (index, name, value) => {
    const newQuestions = [...formik.values.questions];
    newQuestions[index][name] = value;
    formik.setFieldValue("questions", newQuestions);
  };

  const handleFieldInputChange = (qIndex, fIndex, name, value) => {
    const newQuestions = [...formik.values.questions];
    newQuestions[qIndex].fields[fIndex][name] = value;
    formik.setFieldValue("questions", newQuestions);
  };

  const addField = (qIndex) => {
    const newQuestions = [...formik.values.questions];
    newQuestions[qIndex].fields.push({
      label: "",
      fieldType: "text",
      options: [],
      isRequired: false,
      image: "",
      placeholder: "",
    });
    formik.setFieldValue("questions", newQuestions);
  };

  const removeField = (qIndex, fIndex) => {
    const newQuestions = [...formik.values.questions];
    newQuestions[qIndex].fields.splice(fIndex, 1);
    formik.setFieldValue("questions", newQuestions);
  };

  const removeQuestion = (qIndex) => {
    const newQuestions = [...formik.values.questions];
    newQuestions.splice(qIndex, 1);
    formik.setFieldValue("questions", newQuestions);
  };

  if (!category) return <div>Loading...</div>; // Show loading indicator while data is being fetched

  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1 className="text-3xl text-center font-medium title-font mb-10 tracking-widest">
          Edit Category
        </h1>
        <form className="max-w-md mx-auto" onSubmit={formik.handleSubmit}>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="name"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Category Name
            </label>
            {formik.touched.name && formik.errors.name && (
              <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="picture"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
              value={formik.values.picture}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              htmlFor="picture"
              className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Category Image URL
            </label>
            {formik.touched.picture && formik.errors.picture && (
              <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                {formik.errors.picture}
              </div>
            )}
          </div>

          <div className="mb-5">
            {formik.values.questions.map((question, qIndex) => (
              <div key={qIndex} className="mb-6">
                <input
                  type="text"
                  value={question?.question || ""}
                  onChange={(e) =>
                    handleQuestionInputChange(
                      qIndex,
                      "question",
                      e.target.value
                    )
                  }
                  placeholder="Question Text"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                />
                <button
                  type="button"
                  onClick={() => removeQuestion(qIndex)}
                  className="text-red-500 text-xs"
                >
                  Remove Question
                </button>

                {question?.fields?.map((field, fIndex) => (
                  <div key={fIndex} className="mt-5">
                    <input
                      type="text"
                      value={field?.label || ""}
                      onChange={(e) =>
                        handleFieldInputChange(
                          qIndex,
                          fIndex,
                          "label",
                          e.target.value
                        )
                      }
                      placeholder="Field Label"
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                    />
                    <select
                      value={field?.fieldType || "text"}
                      onChange={(e) =>
                        handleFieldInputChange(
                          qIndex,
                          fIndex,
                          "fieldType",
                          e.target.value
                        )
                      }
                      className="mt-2"
                    >
                      <option value="text">Text</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="dropdown">Dropdown</option>
                      <option value="number">Number</option>
                      <option value="date">Date</option>
                    </select>
                    {(field?.fieldType === "dropdown" ||
                      field?.fieldType === "checkbox") && (
                      <textarea
                        value={field?.options?.join(",") || ""}
                        onChange={(e) =>
                          handleFieldInputChange(
                            qIndex,
                            fIndex,
                            "options",
                            e.target.value.split(",")
                          )
                        }
                        placeholder="Options (comma separated)"
                        className="mt-2 block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                      />
                    )}
                    <button
                      type="button"
                      onClick={() => removeField(qIndex, fIndex)}
                      className="text-red-500 text-xs mt-2"
                    >
                      Remove Field
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addField(qIndex)}
                  className="text-blue-500 text-xs mt-2"
                >
                  Add Field
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => {
                formik.setFieldValue("questions", [
                  ...formik.values.questions,
                  { questionText: "", fields: [] },
                ]);
              }}
              className="text-blue-500 text-xs mt-2"
            >
              Add Question
            </button>
          </div>

          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              style={{ backgroundColor: "#007bff" }}
              className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CategoryDetails;
