import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import { usePostContext } from "../contexts/PostContext";

const BookingDetail = () => {
  const { fetchSingleService } = useFetchContext();
  const { updateFeaturedService } = usePostContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      let response = await fetchSingleService(id);
      if (response?.success) {
        setServiceDetails(response?.data);
      }
    })();
  }, [refreshState]);

  const handleToggle = async (status) => {
    console.log("🚀 ~ handleToggle ~ status:", status);
    setLoading(true);
    let dataToSend = {
      isFeatured: status,
    };
    const response = await updateFeaturedService(id, dataToSend);
    if (response?.success) {
      setRefreshState((prev) => !prev);
    }
    setLoading(false);
  };

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {serviceDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              Service Detail
            </h2>
          </div>
          <div
            className="p-4 mb-4 text-sm w-full flex items-center justify-between gap-y-6 text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
            role="alert"
          >
            <span className="text-lg" style={{ color: currentColor }}>
              Make This Service Featured
            </span>
            <div className="flex justify-between items-center py-2">
              {loading ? (
                <Loading
                  width={25}
                  bgColor={"transparent"}
                  color={"black"}
                  variant={"round"}
                />
              ) : (
                <div
                  onClick={() =>
                    handleToggle(!serviceDetails?.service?.isFeatured)
                  }
                  className={`w-14 h-8 flex items-center rounded-full cursor-pointer transition-all duration-300 ${
                    serviceDetails?.service?.isFeatured
                      ? "bg-blue-500"
                      : "bg-gray-400"
                  }`}
                  style={{
                    backgroundColor: serviceDetails?.service?.isFeatured
                      ? currentColor
                      : "#ccc",
                  }}
                >
                  <div
                    className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                      serviceDetails?.service?.isFeatured
                        ? "translate-x-6"
                        : "translate-x-1"
                    }`}
                  ></div>
                </div>
              )}
            </div>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>Service Name</div>
                    <div className="">
                      {serviceDetails?.service?.serviceName || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Category Name</div>
                    <div className="">
                      {serviceDetails?.service?.category?.name || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Price</div>
                    <div className="">
                      {serviceDetails?.service?.price || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Duration</div>
                    <div className="">
                      {serviceDetails?.service?.duration + " Mins" || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Description</div>
                    <div className="">
                      {serviceDetails?.service?.description || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-start justify-start md:justify-start flex w-full font-medium title-font tracking-wide">
              Provider Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>Name</div>
                    <div className="">
                      {serviceDetails?.provider?.user?.firstName || "--"}{" "}
                      {serviceDetails?.provider?.user?.lastName || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Email</div>
                    <div className="">
                      {serviceDetails?.provider?.user?.email || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Phone Number</div>
                    <div className="">
                      {serviceDetails?.provider?.user?.phoneNumber || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Status</div>
                    <div className="">
                      {serviceDetails?.provider?.status || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingDetail;
