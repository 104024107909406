import React from "react";

const ToggleButton = ({ isOn, onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className={`relative flex items-center w-16 h-8 rounded-full ${
        isOn ? "bg-green-500" : "bg-gray-400"
      } transition-colors duration-300`}
    >
      <span
        className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
          isOn ? "translate-x-9" : "translate-x-1"
        }`}
      ></span>
    </button>
  );
};

export default ToggleButton;
