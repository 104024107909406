import React from "react";
import { useStateContext } from "../contexts/ContextProvider";

const Header = ({ category, title }) => {
  const { currentColor } = useStateContext();
  return (
    <div className="mb-10">
      <p
        style={{ color: currentColor }}
        className="text-3xl font-extralight tracking-tight dark:text-white-shade text-black-shade"
      >
        {title}
      </p>
    </div>
  );
};

export default Header;
