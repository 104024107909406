import React, { useEffect, useState } from "react";
import { Header } from "../components";
import { useAdminContext } from "../contexts/AdminContext";
import { useMemo } from "react";
import Table from "../components/Table";
import { useStateContext } from "../contexts/ContextProvider";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
const Services = () => {
  const { fetchAllServices } = useFetchContext();
  const { currentMode, currentColor } = useStateContext();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0, // zero-based page index
    pageSize: 10,
  });
  const [services, setServices] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (pagination) {
      (async () => {
        const { data, totalCount } = await fetchAllServices(
          pagination.pageIndex,
          pagination.pageSize
        );
        setServices(data);
        setTotalCount(totalCount);
      })();
    }
  }, [pagination]);

  const columns = useMemo(() => [
    {
      accessorKey: "serviceName",
      header: "Service Name",
      size: 80,
    },
    {
      accessorKey: "category.name",
      header: "Category",
      size: 80,
    },
    {
      accessorKey: "state",
      header: "State",
      size: 80,
    },
    {
      accessorKey: "country",
      header: "Country",
      size: 80,
    },
    {
      accessorKey: "price",
      header: "Price",
      size: 80,
    },
    {
      accessorFn: (row) => <span>{row.isFeatured ? "Yes" : "No"}</span>,
      accessorKey: "isFeatured",
      header: "Featured",
      size: 80,
    },
  ]);
  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <div className="flex w-full justify-between items-center">
        <Header title="Services" />
      </div>
      {services?.length < 0 ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={services}
            pagination={pagination}
            onPaginationChange={(newPagination) => {
              console.log("Pagination updated", newPagination);
              setPagination(newPagination);
            }}
            totalCount={totalCount}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Details">
                  <IconButton
                    onClick={() => {
                      navigate(`/service/${row.original._id}`);
                    }}
                  >
                    <Send className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default Services;
