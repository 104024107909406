import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import ToggleButton from "../components/ToggleButton";

const UserDetail = () => {
  const { fetchSingleUserByID } = useFetchContext();
  const { toggleUserRestriction } = useFetchContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [userDetails, setUserDetails] = useState(null);
  const [isOn, setIsOn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await fetchSingleUserByID(id);
      if (response?.success) {
        setUserDetails(response.data);
        setIsOn(response.data?.isRestricted || false);
      }
    })();
  }, [id]);

  const handleToggle = async () => {
    const newState = !isOn;
    await toggleUserRestriction(newState, id);
    setIsOn(newState);
  };

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {userDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl font-medium title-font tracking-wide">
              User Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex flex-col text-xs md:text-sm">
                <div className="flex justify-between items-center pb-4 border-b border-gray-300">
                  <div className="flex items-center gap-4">
                    <img
                      src={userDetails?.profilePicture?.url}
                      alt="Profile"
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <p className="text-lg font-semibold">
                      {userDetails?.firstName || "--"}{" "}
                      {userDetails?.lastName || "--"}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p className="text-lg font-semibold">Restrict:</p>
                    <ToggleButton isOn={isOn} onToggle={handleToggle} />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 text-base mt-4">
                  <div>Email</div>
                  <div>{userDetails?.email || "--"}</div>

                  <div>Phone Number</div>
                  <div>{userDetails?.phoneNumber || "--"}</div>

                  <div>Date of Birth</div>
                  <div>
                    {moment(userDetails?.dob).format("YYYY-MM-DD") || "--"}
                  </div>

                  <div>Account Type</div>
                  <div>{userDetails?.authType || "--"}</div>

                  <div>Role</div>
                  <div>{userDetails?.role?.join(", ") || "--"}</div>

                  <div>Joining Date</div>
                  <div>
                    {moment(userDetails?.createdAt).format("YYYY-MM-DD") ||
                      "--"}
                  </div>

                  <div>Status</div>
                  <div>
                    {userDetails?.isVerified ? "Verified" : "Unverified"}
                  </div>
                </div>

                {/* User Preferences */}
                <div className="mt-6">
                  <h3 className="text-xl font-medium title-font mb-4">
                    Preferences
                  </h3>
                  <div className="grid grid-cols-2 gap-4 text-base">
                    <div>Address</div>
                    <div>{userDetails?.preferences?.address || "--"}</div>

                    <div>City</div>
                    <div>{userDetails?.preferences?.city || "--"}</div>

                    <div>State</div>
                    <div>{userDetails?.preferences?.state || "--"}</div>

                    <div>Country</div>
                    <div>{userDetails?.preferences?.country || "--"}</div>

                    <div>Postal Code</div>
                    <div>{userDetails?.preferences?.postalCode || "--"}</div>

                    <div>Language</div>
                    <div>{userDetails?.preferences?.language || "--"}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetail;
