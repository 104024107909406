import React, { useEffect, useState } from "react";
import { Header } from "../components";
import { useAdminContext } from "../contexts/AdminContext";
import { useMemo } from "react";
import Table from "../components/Table";
import { useStateContext } from "../contexts/ContextProvider";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
const Bookings = () => {
  const { fetchAllBookings } = useFetchContext();
  const { currentMode, currentColor } = useStateContext();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0, // zero-based page index
    pageSize: 10,
  });
  const [bookings, setBookings] = useState([]);
  console.log("🚀 ~ Bookings ~ bookings:", bookings);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (pagination) {
      (async () => {
        const { data, totalCount } = await fetchAllBookings(
          pagination.pageIndex,
          pagination.pageSize
        );
        setBookings(data);
        setTotalCount(totalCount);
      })();
    }
  }, [pagination]);

  const columns = useMemo(() => [
    {
      accessorKey: "service.serviceName",
      header: "Service Name",
      size: 80,
    },
    {
      accessorKey: "selectedSlot.date",
      header: "Date",
      size: 80,
      accessorFn: (row) => <p>{moment(row).format("YYYY-MM-DD")}</p>,
    },
    {
      accessorKey: "user.firstName",
      header: "Client",
      size: 80,
    },
    {
      accessorKey: "details.price",
      header: "Price",
      size: 80,
    },
    {
      accessorFn: (row) =>
        row.status === "Completed" ? (
          <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300">
            Completed
          </span>
        ) : row.status === "Pending" ? (
          <span className="bg-orange-100 text-orange-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-orange-900 dark:text-orange-300">
            Pending
          </span>
        ) : row.status === "Cancelled" ? (
          <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-red-900 dark:text-red-300">
            Cancelled
          </span>
        ) : row.status === "Inprogress" ? (
          <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-yellow-900 dark:text-yellow-300">
            In Progress
          </span>
        ) : row.status === "Dispute" ? (
          <span className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-purple-900 dark:text-purple-300">
            Dispute
          </span>
        ) : row.status === "Accepted" ? (
          <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-blue-900 dark:text-blue-300">
            Accepted
          </span>
        ) : row.status === "Rejected" ? (
          <span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-gray-900 dark:text-gray-300">
            Rejected
          </span>
        ) : row.status === "Expired" ? (
          <span className="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-pink-900 dark:text-pink-300">
            Expired
          </span>
        ) : (
          <span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-gray-900 dark:text-gray-300">
            Unknown
          </span>
        ),
      accessorKey: "status",
      header: "Status",
      size: 80,
    },
  ]);
  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <div className="flex w-full justify-between items-center">
        <Header title="Bookings" />
      </div>
      {bookings?.length < 0 === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={bookings}
            pagination={pagination}
            onPaginationChange={(newPagination) => {
              setPagination(newPagination);
            }}
            totalCount={totalCount}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Details">
                  <IconButton
                    onClick={() => {
                      navigate(`/booking/${row.original._id}`);
                    }}
                  >
                    <Send className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default Bookings;
