import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import { usePostContext } from "../contexts/PostContext";
import { useStateContext } from "../contexts/ContextProvider";

const BookingDetail = () => {
  const { getCmsSection } = useFetchContext();
  const { updateCmsSection } = usePostContext(); // Replace with the actual API call for updating CMS data
  const { currentMode, currentColor } = useStateContext();
  const [cmsDetails, setCmsDetails] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggle = (key) => {
    setCmsDetails((prevDetails) => ({
      ...prevDetails,
      [key]: !prevDetails[key],
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    let dataToSend = {
      preferences: cmsDetails,
    };
    const response = await updateCmsSection(dataToSend);
    if (response?.success) {
      setRefreshState((prev) => !prev);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      let response = await getCmsSection();
      if (response?.success) {
        setCmsDetails(response?.data);
      }
    })();
  }, [refreshState]);

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {cmsDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              Content Management System
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="space-y-4">
                {Object.keys(cmsDetails).map((key) => (
                  <div
                    key={key}
                    className="flex justify-between items-center py-2"
                  >
                    <span className="text-base font-medium capitalize">
                      {key.replace(/([A-Z])/g, " $1")}
                    </span>
                    <div
                      onClick={() => handleToggle(key)}
                      className={`w-14 h-8 flex items-center rounded-full cursor-pointer transition-all duration-300 ${
                        cmsDetails[key] ? "bg-blue-500" : "bg-gray-400"
                      }`}
                      style={{
                        backgroundColor: cmsDetails[key]
                          ? currentColor
                          : "#ccc",
                      }}
                    >
                      <div
                        className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                          cmsDetails[key] ? "translate-x-6" : "translate-x-1"
                        }`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              disabled={loading}
              style={{ backgroundColor: currentColor }}
              className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700"
              onClick={handleSave}
            >
              {loading ? "Saving" : "Save"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingDetail;
