import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import photo from "../data/logo.png";
import UserOrder from "../components/UserOrder";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";

const CustomerDetail = () => {
  const { fetchSingleUser } = useFetchContext();
  const { currentMode } = useStateContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    (async () => {
      let { data, success } = await fetchSingleUser(id);
      if (success) {
        console.log("🚀 ~ data:", data);
        setUserDetails(data);
      }
    })();
  }, []);

  return (
    <div className="container px-5 py-24 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {userDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="profile"
            src={
              userDetails?.user?.avatar?.url
                ? userDetails.user.avatar.url
                : photo
            }
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium ">
              {userDetails?.user?.firstName} {userDetails?.user?.lastName}
            </h1>
            <p className="leading-relaxed mb-8">{userDetails?.user?.email}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full mb-8"></div>
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              User Bookings
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
            {userDetails?.userBookings?.length > 0 ? (
              userDetails.userBookings.map((booking, i) => (
                <UserOrder
                  key={i}
                  id={booking?._id}
                  packageName={booking?.packageId?.name}
                  date={booking?.selectedSlot?.date}
                  price={booking?.packageId?.price}
                  time={booking?.selectedSlot?.time}
                />
              ))
            ) : (
              <div className="flex w-full col-span-3 items-center text-black-shade dark:text-white-shade justify-center">
                <h2 className="mt-4 mb-8 text-2xl">No Orders Found</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetail;
