import React, { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { useNavigate } from "react-router-dom";

const CreateCategory = () => {
  const { currentColor } = useStateContext();
  const { PostCategory } = usePostContext();
  const navigate = useNavigate();

  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryImagePreview, setCategoryImagePreview] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);


  // Manage state for questions and fields
  const [questions, setQuestions] = useState([
    {
      question: "",
      fields: [
        {
          label: "",
          fieldType: "text", // default field type
          options: [],
          isRequired: false,
          image: "",
          placeholder: "",
        },
      ],
    },
  ]);

  // Handle adding a question
  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: "",
        fields: [
          {
            label: "",
            fieldType: "text",
            options: [],
            isRequired: false,
            image: "",
            placeholder: "",
          },
        ],
      },
    ]);
  };

  const categoryImageRef = useRef(null);

  // Handle removing a question
  const removeQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleRemoveCategoryImage = () => {
    setCategoryImage(null);
    setCategoryImagePreview(null);
    categoryImageRef.current.value = "";
  };

  // Handle adding a field
  const addField = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields.push({
      label: "",
      fieldType: "text",
      options: [],
      isRequired: false,
      image: "",
      placeholder: "",
    });
    setQuestions(newQuestions);
  };

  // Handle removing a field
  const removeField = (questionIndex, fieldIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields.splice(fieldIndex, 1);
    setQuestions(newQuestions);
  };

  // Handle field type change (for dropdown, checkbox, etc.)
  const handleFieldTypeChange = (questionIndex, fieldIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields[fieldIndex].fieldType = value;
    if (value === "dropdown" || value === "checkbox") {
      newQuestions[questionIndex].fields[fieldIndex].options = [];
    }
    setQuestions(newQuestions);
  };

  // Handle options for dropdown and checkbox
  const handleOptionsChange = (questionIndex, fieldIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields[fieldIndex].options = value.split(",");
    setQuestions(newQuestions);
  };

  // Handle input change for other fields (label, placeholder, etc.)
  const handleFieldInputChange = (questionIndex, fieldIndex, name, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields[fieldIndex][name] = value;
    setQuestions(newQuestions);
  };

  // Handle question input change
  const handleQuestionInputChange = (index, name, value) => {
    const newQuestions = [...questions];
    newQuestions[index][name] = value;
    setQuestions(newQuestions);
  };

  // Handle image selection and conversion to base64
  const handleImageChange = (questionIndex, fieldIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].fields[fieldIndex].image = reader.result;
        setQuestions(newQuestions);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle image removal
  const handleRemoveImage = (questionIndex, fieldIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].fields[fieldIndex].image = "";
    setQuestions(newQuestions);
  };

  // Handle category image selection and conversion to base64
  const handleCategoryImageChange = (e) => {
    const file = e.target.files[0];
    if (e.target.files.length > 0) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setCategoryImage(reader.result.toString());
      };
      reader.readAsDataURL(file);
      setCategoryImagePreview(URL.createObjectURL(file));
    }
  };

  // Handle category image URL change (manual URL input)

  const handleSubmit = async (values) => {
    setBtnLoading(true); // Show loading state
    const categoryData = {
      name: values.name,
      picture: categoryImage, // Base64 encoded image
      questions: questions.map((question) => ({
        question: question.question,
        fields: question.fields.map((field) => ({
          label: field.label,
          fieldType: field.fieldType,
          options: field.options,
          isRequired: field.isRequired,
          image: field.image, // Base64 encoded image (optional)
          placeholder: field.placeholder,
        })),
      })),
    };

    try {
      const { data } = await PostCategory(categoryData); // Call API to post category
      if (data.success) {
        toast.success("Category Created Successfully");
        formik.resetForm(); // Reset the form
        setQuestions([
          {
            question: "",
            fields: [
              {
                label: "",
                fieldType: "text", // default field type
                options: [],
                isRequired: false,
                image: "",
                placeholder: "",
              },
            ],
          },
        ]); // Reset questions to default
        navigate("/categories"); // Navigate to categories page
      }
    } catch (error) {
      console.error("Error creating category:", error);
      toast.error("Failed to Create Category");
    } finally {
      setBtnLoading(false); // Remove loading state
    }
  };

  const CreateCategorySchema = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .required("Category Name is Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: CreateCategorySchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1 className="text-3xl text-center font-medium title-font mb-10 tracking-widest">
          Create Category
        </h1>
        <form className="max-w-md mx-auto" onSubmit={formik.handleSubmit}>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="name"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 peer"
              placeholder=""
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Category Name
            </label>
          </div>
          {formik.touched.name && formik.errors.name && (
            <div className="text-black-shade dark:text-white-shade text-xs mt-1">
              {formik.errors.name}
            </div>
          )}
          {/* Category Image */}
          <div className="relative z-0 w-full mb-5 group flex flex-col">
            {categoryImagePreview && (
              <div className="mb-4">
                <img
                  src={categoryImagePreview}
                  alt="Category Preview"
                  className="w-32 h-32 object-cover"
                />
                <button
                  type="button"
                  onClick={handleRemoveCategoryImage}
                  className="text-red-500 text-xs mt-2"
                >
                  Remove Image
                </button>
              </div>
            )}
            <span className=" absolute text-lg text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] my-6 ">
              Category Image
            </span>
            <input
              type="file"
              ref={categoryImageRef}
              accept="image/*"
              onChange={handleCategoryImageChange}
              className="mt-2"
            />
          </div>
          <div className="">
            {questions.map((question, qIndex) => (
              <div key={qIndex} className="">
                <input
                  type="text"
                  value={question.question}
                  onChange={(e) =>
                    handleQuestionInputChange(
                      qIndex,
                      "question",
                      e.target.value
                    )
                  }
                  placeholder="Question Text"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                />
                <button
                  type="button"
                  onClick={() => removeQuestion(qIndex)}
                  className="text-red-500 text-xs"
                >
                  Remove Question
                </button>

                {question.fields.map((field, fIndex) => (
                  <div key={fIndex} className="mt-5">
                    <input
                      type="text"
                      value={field.label}
                      onChange={(e) =>
                        handleFieldInputChange(
                          qIndex,
                          fIndex,
                          "label",
                          e.target.value
                        )
                      }
                      placeholder="Field Label"
                      className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                    />
                    <div className="relative right-0 my-5 w-full">
                      <select
                        name="selectedRoom"
                        value={field.fieldType}
                        onChange={(e) =>
                          handleFieldTypeChange(qIndex, fIndex, e.target.value)
                        }
                        className="rounded border w-full border-gray-700  bg-transparent appearance-none py-2 focus:outline-none  pl-3 pr-10"
                      >
                        <option value="text" className="py-2 px-1">
                          Text
                        </option>
                        <option value="checkbox" className="py-2 px-1">
                          Checkbox
                        </option>
                        <option value="dropdown" className="py-2 px-1">
                          Dropdown
                        </option>
                        <option value="number" className="py-2 px-1">
                          Number
                        </option>
                        <option value="date" className="py-2 px-1">
                          Date
                        </option>
                      </select>
                    </div>
                    {field.fieldType === "dropdown" ||
                    field.fieldType === "checkbox" ? (
                      <textarea
                        value={field.options.join(",")}
                        onChange={(e) =>
                          handleOptionsChange(qIndex, fIndex, e.target.value)
                        }
                        placeholder="Options (comma separated)"
                        className="my-2 block px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0"
                      />
                    ) : null}
                    {/* Image Picker */}
                    <div>
                      {/* {field.image && (
                        <div className="mb-4">
                          <img
                            src={field.image}
                            alt="Field Preview"
                            className="w-32 h-32 object-cover"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(qIndex, fIndex)}
                            className="text-red-500 text-xs mt-2"
                          >
                            Remove Image
                          </button>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(qIndex, fIndex, e)}
                        className="mt-2"
                      /> */}
                    </div>
                    <button
                      type="button"
                      onClick={() => removeField(qIndex, fIndex)}
                      className="text-red-500 text-xs mt-2"
                    >
                      Remove Field
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addField(qIndex)}
                  className="text-blue-500 text-lg my-5 "
                >
                  Add Field
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={addQuestion}
            className="text-blue-500 text-lg "
          >
            Add Question
          </button>

          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: currentColor }}
              disabled={btnLoading}
            >
              {btnLoading ? "Submitting..." : "Create Category"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateCategory;
