import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import BookingSession from "../components/BookingSession";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

const BookingDetail = () => {
  const { fetchSingleBooking } = useFetchContext();
  const {
    ReturnAppointmentPayment,
    acceptPendingBooking,
    rejectPendingBooking,
  } = usePostContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [bookingDetails, setBookingDetails] = useState(null);
  console.log("Booking Details >", bookingDetails?.details?.location?.address);
  const [refundLoading, setRefundLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceToRefund, setPriceToRefund] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [pendingLoading, setPendingLoading] = useState({
    state: false,
    message: "",
  });
  const navigate = useNavigate();

  const handleOpen = (values) => {
    setPriceToRefund(values.priceToRefund);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePendingAccept = async () => {
    setPendingLoading({ state: true, message: "Accepting" });
    let dataToSend = {
      bookingId: id,
    };
    await acceptPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    setRefreshState((prev) => !prev);
  };
  const handlePendingReject = async () => {
    setPendingLoading({ state: true, message: "Rejecting" });
    let dataToSend = {
      bookingId: id,
    };
    await rejectPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    navigate("/bookings");
  };

  useEffect(() => {
    (async () => {
      let response = await fetchSingleBooking(id);
      if (response?.success) {
        setBookingDetails(response?.data);
      }
    })();
  }, [refreshState]);

  const refundAmount = async () => {
    handleClose();
    setRefundLoading(true);
    let data = {
      paymentIntentId: bookingDetails.booking.paymentResult.id,
      priceToRefund,
      bookingId: bookingDetails.booking._id,
    };
    await ReturnAppointmentPayment(data);
    setRefundLoading(false);
    navigate("/bookings");
  };

  //FORM SCHEMA FOR FORMIK
  const RefundSchema = Yup.object({
    priceToRefund: Yup.number().required("Refund Amount is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      priceToRefund: "",
    },
    validationSchema: RefundSchema,
    onSubmit: (values) => {
      handleOpen(values);
    },
  });

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {bookingDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="container px-5 py-5 flex flex-col space-y-6 dark:text-white-shade text-black-shade mx-auto">
            <h2 className="text-4xl font-medium title-font mb-4">
              Booking Details
            </h2>

            {/* Service Details */}
            <div className="grid grid-cols-2 gap-4 text-base">
              <div>Service</div>
              <div>{bookingDetails?.service?.serviceName || "--"}</div>

              <div>Date</div>
              <div>
                {moment(bookingDetails?.details?.date)
                  .utc()
                  .format("YYYY-MM-DD") || "--"}
              </div>

              <div>Time</div>
              <div>
                {moment(
                  bookingDetails?.details?.slot?.startTime,
                  "HH:mm"
                ).format("hh:mm A") || "--"}{" "}
                -{" "}
                {moment(bookingDetails?.details?.slot?.endTime, "HH:mm").format(
                  "hh:mm A"
                ) || "--"}
              </div>

              <div>Price</div>
              <div>${bookingDetails?.details?.price || "--"}</div>

              <div>Client Name</div>
              <div>
                {bookingDetails?.user?.firstName || "--"}{" "}
                {bookingDetails?.user?.lastName || "--"}
              </div>

              <div>Client Email</div>
              <div>{bookingDetails?.user?.email || "--"}</div>

              <div>Client Phone</div>
              <div>{bookingDetails?.details?.phone || "--"}</div>

              <div>Status</div>
              <div>{bookingDetails?.status || "--"}</div>
            </div>

            {/* Location Details */}
            <h2 className="text-2xl font-medium title-font mb-4">
              Location Details
            </h2>
            <div className="grid grid-cols-2 gap-4 text-base">
              <div>Apartment</div>
              <div>{bookingDetails?.details?.location?.apt || "--"}</div>

              <div>Address</div>
              <div>{bookingDetails?.details?.location?.address || "--"}</div>

              <div>City</div>
              <div>{bookingDetails?.details?.location?.city || "--"}</div>

              <div>State</div>
              <div>{bookingDetails?.details?.location?.state || "--"}</div>

              <div>Zip Code</div>
              <div>{bookingDetails?.details?.location?.zip || "--"}</div>
            </div>

            {/* Provider Details */}
            <h2 className="text-2xl font-medium title-font mb-4">
              Provider Details
            </h2>
            <div className="grid grid-cols-2 gap-4 text-base">
              <div>Name</div>
              <div>
                {bookingDetails?.provider?.user?.firstName || "--"}{" "}
                {bookingDetails?.provider?.user?.lastName || "--"}
              </div>

              <div>Email</div>
              <div>{bookingDetails?.provider?.user?.email || "--"}</div>

              <div>Phone Number</div>
              <div>{bookingDetails?.provider?.user?.phoneNumber || "--"}</div>

              <div>Join Date</div>
              <div>
                {moment(bookingDetails?.provider?.createdAt)
                  .utc()
                  .format("YYYY-MM-DD") || "--"}
              </div>

              <div>Status</div>
              <div>{bookingDetails?.provider?.status || "--"}</div>
            </div>

            {/* Additional Questions */}
            <h2 className="text-2xl font-medium title-font mb-4">
              Questionnaire Responses
            </h2>
            <div className="grid grid-cols-2 gap-4 text-base">
              {bookingDetails?.questionResponses?.map((response) => (
                <React.Fragment key={response._id}>
                  <div>{response.question}</div>
                  <div>{response.answer.join(", ") || "--"}</div>
                </React.Fragment>
              ))}
            </div>
          </div>

          <CustomModal open={open} handleClose={handleClose}>
            <div className="w-full flex flex-col">
              <div className="py-4 font-semibold">
                Are you sure want to Refund Amount ?
              </div>
              <div className="ml-auto font-semibold">
                <button
                  onClick={handleClose}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                >
                  No
                </button>
                <button
                  onClick={refundAmount}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Yes
                </button>
              </div>
            </div>
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default BookingDetail;
