import React from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/logo.png";
import { useAuthContext } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const UserProfile = () => {
  const { currentColor, setIsClicked, initialState } = useStateContext();
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <button
          onClick={() => setIsClicked(initialState)}
          className="cursor-pointer rounded-full text-black-shade dark:text-white-shade
          hover:text-white-shade hover:bg-black-shade dark:hover:text-black-shade p-2 dark:hover:bg-white-shade"
        >
          <MdOutlineCancel className=" h-5 w-5" />
        </button>
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full object-cover h-24 w-24"
          src={user?.avatar?.url || avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {user.name}{" "}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {user.email}{" "}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <Link
            to={"/admin/profile"}
            key={index}
            onClick={() => setIsClicked(initialState)}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-secondary-dark-bg"
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </Link>
        ))}
      </div>
      <div className="mt-5">
        <button
          style={{ backgroundColor: currentColor }}
          className="text-base text-white-shade rounded-lg p-3 w-full hover:drop-shadow-xl"
          onClick={() => {
            logout();
            setIsClicked(initialState);
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
