import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import { usePostContext } from "../contexts/PostContext";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

const KycDetail = () => {
  const { fetchSingleKyc } = useFetchContext();
  const { updateKyc } = usePostContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [providerDetails, setProviderDetails] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [pendingLoading, setPendingLoading] = useState({
    state: false,
    message: "",
  });
  const navigate = useNavigate();

  const handlePendingAction = async (action) => {
    const status = action === "accept" ? "verified" : "suspended";
    const message = action === "accept" ? "Accepting" : "Rejecting";

    setPendingLoading({ state: true, message });
    let dataToSend = {
      providerId: id,
      status,
    };
    await updateKyc(dataToSend);
    setPendingLoading({ state: false, message: "" });

    if (action === "accept") {
      setRefreshState((prev) => !prev);
    } else {
      navigate("/kyc");
    }
  };

  useEffect(() => {
    (async () => {
      let response = await fetchSingleKyc(id);
      if (response?.success) {
        setProviderDetails(response?.data);
      }
    })();
  }, [refreshState]);

  const renderDocPreview = (doc) => {
    const extension = doc.url.split(".").pop().toLowerCase(); // Extract file extension

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <img
            src={doc.url}
            alt="Document Preview"
            className="w-full h-96 object-contain rounded-lg"
          />
        );
      default:
        return (
          <a
            href={doc.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            View Document
          </a>
        );
    }
  };

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {providerDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              KYC Details
            </h2>
          </div>
          {providerDetails?.status === "reviewing" && (
            <div
              className="p-4 mb-4 text-sm w-full flex flex-col gap-y-6 text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
              role="alert"
            >
              <span className="text-sm" style={{ color: currentColor }}>
                The provider docs is currently in Review. Please accept or
                reject the to notify the user about the status of their
                Acceptance.
              </span>
              {pendingLoading.state ? (
                <div className="flex w-full items-center justify-center text-lg">
                  {pendingLoading.message}...
                </div>
              ) : (
                <div className="grid grid-cols-2">
                  <button
                    type="submit"
                    onClick={() => handlePendingAction("accept")}
                    className="text-black-shade flex items-center justify-center gap-x-2 dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosCheckmark size={20} /> Accept
                  </button>
                  <button
                    type="submit"
                    onClick={() => handlePendingAction("reject")}
                    className="text-black-shade flex items-center justify-center gap-x-2  dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosClose size={20} /> Reject
                  </button>
                </div>
              )}
            </div>
          )}
          {providerDetails?.status === "suspended" && (
            <div
              className="p-4 mb-4 text-sm w-full flex flex-col gap-y-6 text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
              role="alert"
            >
              <span className="text-sm" style={{ color: currentColor }}>
                The provider docs is Suspended. Please accept or reject the to
                notify the user about the status of their Acceptance.
              </span>
              {pendingLoading.state ? (
                <div className="flex w-full items-center justify-center text-lg">
                  {pendingLoading.message}...
                </div>
              ) : (
                <div className="grid grid-cols-2">
                  <button
                    type="submit"
                    onClick={() => handlePendingAction("accept")}
                    className="text-black-shade flex items-center justify-center gap-x-2 dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosCheckmark size={20} /> Accept
                  </button>
                  <button
                    type="submit"
                    onClick={() => handlePendingAction("reject")}
                    className="text-black-shade flex items-center justify-center gap-x-2  dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosClose size={20} /> Reject
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-start justify-start md:justify-start flex w-full font-medium title-font tracking-wide">
              Provider Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <h2
                  style={{ color: currentColor }}
                  className="text-xl md:text-2xl pb-4 font-medium title-font my-2"
                >
                  {providerDetails?.service?.category?.name}
                </h2>
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>Name</div>
                    <div className="">
                      {providerDetails?.user?.firstName || "--"}{" "}
                      {providerDetails?.user?.lastName || "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Join Date</div>
                    <div className="">
                      {moment(providerDetails?.createdAt)
                        .utc()
                        .format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Method</div>
                    <div className="">
                      {providerDetails?.identityVerification?.method?.toUpperCase() ||
                        "--"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Is Restricted</div>
                    <div className="">
                      {providerDetails?.isRestricted === false ? "No" : "Yes"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Status</div>
                    <div className="">{providerDetails?.status || "--"}</div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Phone Number</div>
                    <div className="">
                      {providerDetails?.user?.phoneNumber || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full">
            <h3 className="text-2xl font-medium">Uploaded Documents</h3>
            <div className="space-y-4">
              {providerDetails?.identityVerification?.verificationDocs?.map(
                (doc) => (
                  <div
                    key={doc._id}
                    className="p-4 rounded-lg bg-white dark:bg-secondary-dark-bg shadow-md"
                  >
                    {renderDocPreview(doc)}
                  </div>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KycDetail;
