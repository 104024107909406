import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Customers,
  CustomerDetail,
  Categories,
  CreateCategory,
  Bookings,
  BookingDetail,
  Kyc,
  CategoryDetails,
  KycDetail,
  Providers,
  Users,
  ProviderDetail,
  CMS,
  Services,
  ServiceDetail,
  UserDetail,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
const Home = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor } =
    useStateContext();

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Settings" position="TopCenter">
          <button
            type="button"
            className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
            style={{ backgroundColor: currentColor, borderRadius: "50%" }}
            onClick={() => setThemeSettings(true)}
          >
            <FiSettings />
          </button>
        </TooltipComponent>
      </div>
      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={`dark:bg-main-dark-bg transition-all bg-main-bg min-h-screen w-full ${
          activeMenu ? "md:ml-72 sm:pr-72 md:pr-0" : "flex-2"
        }`}
      >
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div>
          {themeSettings && <ThemeSettings />}
          <Routes>
            <Route path="/*" element={<Navigate to={"/dashboard"} />} />
            <Route path="/dashboard" element={<Ecommerce />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customer/:id" element={<CustomerDetail />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/booking/:id" element={<BookingDetail />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:id" element={<CategoryDetails />} />
            <Route path="/create-category" element={<CreateCategory />} />
            <Route path="/kyc" element={<Kyc />} />
            <Route path="/kyc/:id" element={<KycDetail />} />
            <Route path="/providers" element={<Providers />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service/:id" element={<ServiceDetail />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user/:id" element={<UserDetail />} />
            <Route path="/cms" element={<CMS />} />
            <Route path="/providers/:id" element={<ProviderDetail />} />
            <Route path="/users/:id" element={<UserDetail />} />
            {/* <Route path="/profile" element={<AdminProfile />} />
            {/* <Route path="/edit-product/:id" element={<ProductEdit />} />
            <Route path="/product/:id" element={<ProductDetail />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
